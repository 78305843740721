import React, { useState, useEffect, useRef } from 'react';
import { auth, RecaptchaVerifier, signInWithPhoneNumber } from '../firebaseConfig';
import { TextField, Button, Box, Typography, Container, Snackbar, Alert } from '@mui/material';

const PhoneAuth = ({ onLoginSuccess }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(''); // Added state for message
  const [messageType, setMessageType] = useState(''); // Added state for message type
  const recaptchaVerifier = useRef(null);
  const recaptchaContainerRef = useRef(null);

  useEffect(() => {
    const initializeRecaptcha = () => {
      if (!recaptchaVerifier.current) {
        try {
          recaptchaVerifier.current = new RecaptchaVerifier(auth, recaptchaContainerRef.current, {
            'size': 'invisible',
            'callback': (response) => {
              console.log('reCAPTCHA solved');
            },
            'expired-callback': () => {
              setError('reCAPTCHA expired. Please try again.');
              recaptchaVerifier.current = null;
              initializeRecaptcha();
            }
          });
          console.log('reCAPTCHA initialized successfully');
        } catch (error) {
          console.error('Error initializing reCAPTCHA:', error);
          setError('Failed to initialize reCAPTCHA. Please refresh the page and try again.');
        }
      }
    };

    initializeRecaptcha();

    return () => {
      if (recaptchaVerifier.current) {
        recaptchaVerifier.current.clear();
        recaptchaVerifier.current = null;
      }
    };
  }, []);

  const formatPhoneNumber = (number) => {
    let cleanedNumber = number.replace(/\D/g, '');
    if (!cleanedNumber.startsWith('61')) {
      cleanedNumber = '61' + cleanedNumber.slice(1);
    }
    return `+${cleanedNumber}`;
  };

  const handleSendOtp = async () => {
    setIsLoading(true);
    setError(null);
    setMessage(''); // Clear any existing message
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

    console.log('Attempting to send OTP to:', formattedPhoneNumber);

    if (!recaptchaVerifier.current) {
      console.error('RecaptchaVerifier is not initialized');
      setError('An error occurred. Please refresh the page and try again.');
      setIsLoading(false);
      return;
    }

    try {
      const confirmationResult = await signInWithPhoneNumber(auth, formattedPhoneNumber, recaptchaVerifier.current);
      console.log('OTP sent successfully');
      setConfirmationResult(confirmationResult);
      setMessageType('success'); // Set message type to success
      setMessage('SMS Code has been sent to your phone number.'); // Set success message
    } catch (error) {
      console.error('Error during signInWithPhoneNumber:', error);
      setMessageType('error'); // Set message type to error
      setMessage(`Error during sign-in: ${error.message}`); // Set error message
      
      // Reset reCAPTCHA
      if (recaptchaVerifier.current) {
        try {
          await recaptchaVerifier.current.render();
        } catch (renderError) {
          console.error('Error re-rendering reCAPTCHA:', renderError);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    setIsLoading(true);
    setError(null);
    setMessage(''); // Clear any existing message
    if (!confirmationResult) {
      setError('No SMS Code confirmation pending. Please request a new SMS Code.');
      setIsLoading(false);
      return;
    }

    try {
      const result = await confirmationResult.confirm(otp);
      console.log('OTP verified successfully');
      setMessageType('success'); // Set message type to success
      setMessage('User is signed in.'); // Set success message
      onLoginSuccess(result.user);
    } catch (error) {
      console.error('Error during confirmation', error);
      setMessageType('error'); // Set message type to error
      setMessage(`Error during confirmation: ${error.message}`); // Set error message
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
        <Typography variant="h5">Terms of Hire</Typography>
        <img src="https://1nightrent.com.au/cdn/shop/files/83A50AE0-7523-41D1-9B38-A3D40C63A7CF_170x.png?v=1649223498" alt="logo" />
        <TextField
          label="Phone Number"
          variant="outlined"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          fullWidth
          sx={{ mt: 2, mb: 2 }}
        />
        {!confirmationResult && (
          <Button 
            variant="contained" 
            onClick={handleSendOtp} 
            fullWidth 
            disabled={isLoading}
            sx={{ backgroundColor: 'black', color: 'white' }} // Change color to black
          >
            {isLoading ? 'Sending...' : 'Login'}
          </Button>
        )}
        {confirmationResult && (
          <>
            <TextField
              label="SMS Code"
              variant="outlined"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              fullWidth
              sx={{ mt: 2, mb: 2 }}
            />
            <Button 
              variant="contained" 
              onClick={handleVerifyOtp} 
              fullWidth
              disabled={isLoading}
              sx={{ backgroundColor: 'black', color: 'white' }} // Change color to black
            >
              {isLoading ? 'Verifying...' : 'Confirm SMS Code'}
            </Button>
          </>
        )}
        <div ref={recaptchaContainerRef}></div>
      </Box>
      {message && (
        <Typography
          variant="body1"
          sx={{
            mt: 2,
            mb: 2,
            color: messageType === 'error' ? 'red' : 'green', // Conditional color
            backgroundColor: messageType === 'error' ? '#fdecea' : '#e7f5e6', // Conditional background color
            padding: '10px',
            borderRadius: '5px',
          }}
        >
          {message}
        </Typography>
      )}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PhoneAuth;
