import React, { useRef, useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment';
import { Box, Button, Checkbox, FormControlLabel, Typography, Container, Paper, useMediaQuery, useTheme } from '@mui/material';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  const sigCanvas = useRef({});
  const [agreed, setAgreed] = useState(false);
  const [signed, setSigned] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const updateCanvasWidth = () => {
      const canvas = document.querySelector('.signature-canvas-container');
      if (canvas) {
        setCanvasWidth(canvas.offsetWidth);
      }
    };

    updateCanvasWidth();
    window.addEventListener('resize', updateCanvasWidth);

    return () => window.removeEventListener('resize', updateCanvasWidth);
  }, []);

  const handleClear = () => {
    sigCanvas.current.clear();
    setSigned(false);
  };

  const handleSave = async () => {
    if (sigCanvas.current.isEmpty()) {
      alert("Please provide a signature first.");
      return;
    }
    const signatureData = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    const timestamp = moment().format('YYYY-MM-DD HH:mm:ss');

    try {
      const response = await fetch('http://localhost:5000/save-signature', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ signatureData, timestamp }),
      });

      if (response.ok) {
        alert("Signature saved and email sent successfully!");
        setSigned(true);
      } else {
        alert("Failed to save signature.");
      }
    } catch (error) {
      alert("Error: " + error.message);
    }
  };

  const termsContent = [
    {
      title: "1Nightrent Rent; Agreement",
      content: `The following Terms of Service ("Terms") between you ("you" or "your") and 1Nightrent Pty Ltd. ("we," "our," "us,") describes the terms and conditions on which you may access and use the 1Nightrent website located at 1Nightrent.com.au (the "Site") and related services including 1Nightrent's product rental and sale services (together with the Site and the 1Nightrent Content, as defined below, the "Services"). These Terms also apply to in-store rentals and sales, which are part of the Services. By accessing or using any of the Services, you acknowledge that you have read, understood and agreed to be bound by these Terms.`
    },
    {
      title: "THIS IS A LEGALLY BINDING CONTRACT",
      content: `These Terms of Use constitute a binding legal contract between you and 1Nightrent. These Terms of Use apply to you whether you are browsing or are a customer of 1Nightrent. The Terms of Use in force at any time are published on the Website and you will be taken to have accepted them by accessing or using the Website. If 1Nightrent develops revised terms of Use then on their publication on the Website they will supersede those they replace. If you subsequently access or use the Website you will be taken to have accepted the revised terms of use. If you do not agree with the terms of use application from time to time, do not access, use, submit information to or download from this Website. These Terms of Use may change without notice, so do check from time to time. If you do not agree to any change, you should terminate the use of the Website. Specific Website offerings, incentives, areas, or section may also have additional guidelines which govern your use of this Website. You must comply with them in addition to these Terms of Use.

      This agreement contains all of the terms and conditions regarding your rental of products from 1Nightrent via the website www.1Nightrent.com.au. You agree that rental from our business is subject to all our website terms and conditions of use and privacy policy. These are available on our website and are subject to change from time to time. You agree that you are renting products and ownership of the product remains with 1Nightrent at all times. Our products may be rented for customers who are under the age of 18 however we only rent directly to adults who may rent the products on behalf of a minor.`
    },
    {
      title: "SHIPPING",
      content: `Upon booking, you must inform 1Nightrent if postage is required in advanced. If failure to do so, you will not be refunded or credited for the initial payment.
      All parcels are marked authority to leave. If you require your parcel to be signed for, you must specify this. In such circumstances, you understand and agree that by granting us authority to leave, we and our couriers are released of all responsibility and liability for the orders delivered and left unattended, and that this responsibility and liability transfer to you on delivery.
      We do not take responsibility for orders where an incorrect/ineligible address has been provided. If you have supplied an incorrect address you will not be entitled to a refund of any kind and you may be liable to pay any late fees or a replacement fee if the order is not returned to us. If an order is not delivered on time for your event and we have shipped the order on-time, no credit or refund will be issued.

      With delivery of your product, pre-paid return packaging is provided. Please ensure that you place your garment/s inside the self-addressed satchel and drop it at your nearest Post Office or YELLOW Express Postal Box by 4pm NEXT BUSINESS day. If incorrectly posted, the bond (if one was paid) will be forfeited or you will be required to re-pay the hire price if it does not arrive back in time and cleaned for the following hire.

      1NightRent will not take responsibility for lost item(s) returned in Australia Post boxes. If you lose your return packaging it is your responsibility to notify 1Nightrent immediately and return the garment via trackable express post. Tracking details must be sent to 1Nightrent immediately.  
      If you decide to post back your hired garment/s but have not pre-purchased a return bag via 1Nightrent, you must contact 1Nightrent to be granted permission prior to posting. Not asking for permission may result in a loss of bond or extra charges.`
    },
    {
      title: "DRY CLEANING",
      content: `Each garment is dry cleaned and inspected before orders are posted or collected. However, it is at your own risk and 1Nighrent shall not be held liable for any health-related complaints associated with a product rented from our site. Our products have been worn by other consumers prior to your rental.
      1 (One) Professional dry cleaning is included in your hire price and you agree that no attempts will be made to clean, alter, iron/press garment/s (unless otherwise arranged with a member of (1Nightrent). If additional cleaning is needed, loss of bond or extra payment will be required. 

      You agree to treat the garment with the upmost of care. You are responsible for any damage to the dress due to stains, broken zips, rips, excessive pulling of fabric, theft or loss. If you return a product and it is damaged beyond normal wear and tear then you agree that we shall charge you, and you will pay, for the price of repairing the product or if the product is unable to be repaired you agree to pay up to the full retail value of the garment. This price will be at the Discretion of 1Nightrent.

      Use of fake tan is at your discretion. If it causes permanent staining that won't come out when dry-cleaned, you may be liable to pay up to the full retail of the garment.`
    },
    {
      title: "IN-STORE RETURNS",
      content: `Depending on your event date, returns will differ. As a general policy, all returns are to be dropped back by the following day (regardless if it falls on a weekday/weekend/public holiday). Any special requests may be taken into consideration although it MUST be made prior to your collection NOT after.`
    },
    {
      title: "RETURN OF RENTALS ON TIME",
      content: `You agree to return your rental on the order return date (or next day). If you return your product late you will incur a ten dollar ($10.00) late fee every day for up to three (3) days. After the third (3 days) the bond will be forfeited if paid or another hire price will be charged. Late fee is payable regardless of whether a customer has used a credit for their order. If you have not returned your product within 7 days of the return date your product will be considered stolen and further action will be taken.`
    },
    {
      title: "FAULTY ITEMS",
      content: `If you find the item to have issues (e.g. damage, excessive pulling, or noticeable stains that you were not aware of beforehand), please let us know within an hour of receiving/collecting the item and definitely prior to wearing the item.
      Unfortunately if you do not notify 1Nightrent within this timeframe, we reserve the right to refuse a refund as it will not be clear when the damage was caused or by who. 
      You acknowledge that the items are not brand new and may have some signs of wear & tear. A refund may not be granted if the issue is considered minor or insignificant.`
    },
    {
      title: "LIABILITY AND REPAIR CLAUSE",
      content: `By entering into a dress hire agreement with our company, you, the customer, acknowledge and accept full responsibility for any damages incurred during the hire period. In the event of damage, we will collaborate with you to repair and restore the garment to its original condition. 

      However, if the garment is deemed irreparable, we will work together to determine a mutually agreed value of the Recommended Retail Price (RRP) as compensation. It is important to note that this liability extends to all aspects of the hired garment, including any accessories or accompanying items.`
    },
    {
      title: "COLLECTION",
      content: `If you do not pay the amounts owed to us when due we will pass on the invoices to a collection company to pursue you for collections, including and without limitation attorney and collection fees. 1Nightrent reserves the right to terminate your right to rent products from us at any time at our own discretion should you breach this agreement or for any other reason.`
    },
    {
      title: "SECURING AN OUTFIT",
      content: `Full payment must be paid to secure your garment. If this is not paid, 1NightRent reserves the right to mark the garment as available again. All rentals are final and are non-refundable despite the circumstances. No credit will be given for change of minds or cancellations`
    },
    {
      title: "TRY-ON APPOINTMENT",
      content: `Try on appointment in-store; $10 fee will be deducted from your hire if you make a booking.

      Our products may be rented for customers who are under the age of 18 however we only rent directly to adults who may rent the products on behalf of a minor.`
    },
    {
      title: "APPOINTMENT CANCELLATION POLICY",
      content: `When you book your appointment, you are holding a space on our calendar that is no longer available to our other clients. We understand that sometimes unexpected delays can occur or schedule adjustments. 

      Any cancellation will not be refunded their initial $10 try on fee. If you are more than 15 minutes late for your appointment, we may not be able to accommodate you. In this case, the same cancellation fee will apply. We will do our very best to reschedule your service for another time that is convenient to you.
      If you are after 1 (one) specific item and it is not available during your appointment time, a rescheduled appointment will be made at no extra cost.
      It is the client's responsibility to contact 1Nightrent prior to booking an appointment to ensure the wanted garments are available for your event date. 
      If a garment is hired/booked within 24 hours of the try on appointment, the try-on fee will be deducted from the hire price. There is no refunds or credits available for the try-on fee if you do not decide to hire/book.`
    },
    {
      title: "CANCELLATION POLICY",
      content: "Strictly no cancellation or refund policy."
    }
  ];

  return (
    <Container maxWidth="md" className="terms-conditions-container">
      <Paper elevation={3} sx={{ padding: { xs: 2, sm: 4 }, marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Version: 2.3 - June 2023
        </Typography>
        <Box
          sx={{
            width: '100%',
            height: { xs: 300, sm: 400 },
            border: '1px solid #ccc',
            marginBottom: 2,
            padding: 2,
            overflowY: 'scroll',
            backgroundColor: '#f9f9f9'
          }}
        >
          {termsContent.map((section, index) => (
            <Box key={index} sx={{ marginBottom: 2 }}>
              <Typography variant="h6" gutterBottom>
                {section.title}
              </Typography>
              <Typography variant="body2" paragraph>
                {section.content}
              </Typography>
            </Box>
          ))}
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreed}
              onChange={() => setAgreed(!agreed)}
              name="agree"
              color="primary"
            />
          }
          label="I agree to the terms and conditions"
        />
        <Box
          sx={{
            border: '1px solid #ccc',
            width: '100%',
            height: { xs: 150, sm: 200 },
            marginBottom: 2,
            backgroundColor: '#fff',
            touchAction: 'none'
          }}
          className="signature-canvas-container"
        >
          <SignatureCanvas
            ref={sigCanvas}
            penColor="black"
            canvasProps={{
              width: canvasWidth,
              height: isMobile ? 150 : 200,
              className: 'sigCanvas',
              style: { touchAction: 'none' }
            }}
            onEnd={() => setSigned(true)}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row', gap: 2 }}>
          <Button variant="contained" color="secondary" onClick={handleClear} fullWidth={isMobile}>
            Clear
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={!agreed || !signed}
            fullWidth={isMobile}
          >
            Save
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default TermsAndConditions;