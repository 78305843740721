// src/App.js
import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import PhoneAuth from './components/PhoneAuth';
import TermsAndConditions from './components/TermsAndConditions';
import { Container, Typography } from '@mui/material';

const theme = createTheme();

function App() {
  const [user, setUser] = useState(null);

  const handleLoginSuccess = (user) => {
    setUser(user);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container>
        {user ? (
          <>
            <Typography variant="h4" sx={{ mt: 4, mb: 4 }}>
              Welcome, {user.phoneNumber}
            </Typography>
            <TermsAndConditions />
          </>
        ) : (
          <PhoneAuth onLoginSuccess={handleLoginSuccess} />
        )}
      </Container>
    </ThemeProvider>
  );
}

export default App;
